import React from "react";
import "../styles/App.scss";
import Editor from "./Editor";
import { CredentialContext } from "../scripts/utils";
import { useAuthentication } from "../scripts/useAuthentication";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c2185b",
    },
  },
});

export default function App() {
  const [idToken, setIdToken, buttonRef] = useAuthentication();

  if (idToken === null) {
    return (
      <div className="LoginButtonContainer">
        <div ref={buttonRef} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CredentialContext.Provider value={{ idToken, setIdToken }}>
          <Editor />
        </CredentialContext.Provider>
      </div>
    </ThemeProvider>
  );
}
