import React, { useCallback } from "react";
import { CirclePicker, SketchPicker } from "react-color";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { Button, Modal, Slider } from "@mui/material";

import DrawingPanel from "./DrawingPanel";
import { useSocketIo } from "../scripts/useSocketIo";
import { useColorPickerSetter as useColorPicker } from "../scripts/useColorPicker";
import "../styles/editor.scss";

export default function Editor() {
  const urlWidth = new URLSearchParams(window.location.search).get("width");
  const width = urlWidth ? parseInt(urlWidth) : 8;
  const [isConnected, state, setState] = useSocketIo();
  const [
    colorPickerParentRef,
    selectedColor,
    colorPickerColors,
    colorToUpdate,
    isModalOpen,
    handleSelectedColorUpdate,
    handleCloseModal,
    updateColor,
  ] = useColorPicker(isConnected);

  const setColors = useCallback(
    (pixels) =>
      setState({
        ...state,
        pixels,
      }),
    [setState, state]
  );

  const setBrightness = useCallback(
    (event) => setState({ ...state, brightness: event.target.value }),
    [setState, state]
  );

  const clear = useCallback(
    () => setColors(new Array(width).fill(new Array(width).fill([0, 0, 0]))),
    [setColors, width]
  );

  const rotate = () =>
    setState({ ...state, rotation: (state.rotation + 1) % 4 });

  return (
    <>
      <div
        id="connectionState"
        style={{
          color: isConnected
            ? state.deviceConnected
              ? "green"
              : "yellow"
            : "red",
        }}
      >
        {isConnected ? "Connected" : "Disconnected"}
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modalBox">
          <SketchPicker color={colorToUpdate} onChange={updateColor} />
        </div>
      </Modal>
      {isConnected && (
        <div id="editor" ref={colorPickerParentRef}>
          <h1>Led Liz Box</h1>
          <DrawingPanel
            selectedColor={selectedColor}
            pixels={state.pixels}
            setColors={setColors}
            isConnected={isConnected}
          />
          <div className="sliderContainer">
            <Slider
              defaultValue={0.5}
              max={1}
              min={0}
              step={0.01}
              valueLabelDisplay="auto"
              value={state.brightness}
              onChange={setBrightness}
            />
          </div>
          <CirclePicker
            key={colorPickerColors}
            color={selectedColor}
            onChangeComplete={handleSelectedColorUpdate}
            colors={colorPickerColors}
          />
          <div className="buttonsContainer">
            <Button size="large" variant="contained" onClick={rotate}>
              <div
                style={{
                  height: "24px",
                  transform: `rotate(${90 * state.rotation}deg)`,
                }}
              >
                <RotateRightIcon />
              </div>
            </Button>
            <Button size="large" onClick={clear} variant="contained">
              Clear
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
