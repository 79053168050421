import React from "react";
import { rgbToHex } from "../scripts/utils";

export default function Pixel(props) {
  let { pixel } = props;

  pixel = rgbToHex(pixel);
  pixel = pixel === "#000000" ? "rgba(0, 0, 0, 0)" : pixel;

  return <div className="pixel" style={{ backgroundColor: pixel }}></div>;
}
