import React from "react";
import Pixel from "./Pixel";

export default function Row(props) {
  const { pixelRow } = props;

  return (
    <div className="row">
      {pixelRow.map((pixel, i) => (
        <Pixel key={i} pixel={pixel} />
      ))}
    </div>
  );
}
