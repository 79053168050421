import { useEffect, useRef, useState, useCallback } from "react";

export function useColorPickerSetter(isConnected) {
  const colorPickerParentRef = useRef();
  const pressTimer = useRef();
  const [selectedColor, setSelectedColor] = useState([255, 0, 0]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [colorPickerColors, setColorPickerColors] = useState([
    "#FF0000",
    "#FF8000",
    "#FFFF00",
    "#80FF00",
    "#00FF00",
    "#FFFFFF",
    "#00FFFF",
    "#0080FF",
    "#0000FF",
    "#8000FF",
    "#FF00FF",
    "#000000",
  ]);

  const handleCloseModal = useCallback(
    () => setIsModalOpen(false),
    [setIsModalOpen]
  );

  const handleSelectedColorUpdate = useCallback((color) => {
    setSelectedColor([color.rgb.r, color.rgb.g, color.rgb.b]);
  }, []);

  const updateColor = useCallback(
    (newColor) => {
      setColorPickerColors((prev) => {
        const newColors = [...prev];
        newColors[selectedIndex] = newColor.hex;
        handleSelectedColorUpdate(newColor);
        return newColors;
      });
    },
    [selectedIndex, handleSelectedColorUpdate]
  );

  useEffect(() => {
    if (isConnected === false || isModalOpen) {
      return;
    }

    const buttonRefs =
      colorPickerParentRef.current.getElementsByClassName("circle-picker")[0]
        .childNodes;
    if (pressTimer.current === undefined) {
      pressTimer.current = new Array(buttonRefs.length);
    }

    const handleTouchStart = (idx) => () => {
      pressTimer.current[idx] = setTimeout(() => {
        setSelectedIndex(idx);
        setIsModalOpen(true);
      }, 500);
    };

    const handleTouchEnd = (idx) => () => {
      clearTimeout(pressTimer.current[idx]);
    };

    const handleTouchMove = (idx) => () => {
      clearTimeout(pressTimer.current[idx]);
    };

    const handlers = [...buttonRefs].map((buttonRef, i) => {
      const onTouchStart = handleTouchStart(i);
      const onTouchEnd = handleTouchEnd(i);
      const onTouchMove = handleTouchMove(i);

      buttonRef.addEventListener("touchstart", onTouchStart);
      buttonRef.addEventListener("touchend", onTouchEnd);
      buttonRef.addEventListener("touchmove", onTouchMove);

      return {
        onTouchStart,
        onTouchEnd,
        onTouchMove,
      };
    });

    return () => {
      handlers.forEach((handler, i) => {
        const buttonRef = buttonRefs[i];
        buttonRef.removeEventListener("touchstart", handler.onTouchStart);
        buttonRef.removeEventListener("touchend", handler.onTouchEnd);
        buttonRef.removeEventListener("touchmove", handler.onTouchMove);
      });
    };
  }, [isConnected, selectedIndex, setIsModalOpen, isModalOpen]);

  return [
    colorPickerParentRef,
    selectedColor,
    colorPickerColors,
    colorPickerColors[selectedIndex],
    isModalOpen,
    handleSelectedColorUpdate,
    handleCloseModal,
    updateColor,
  ];
}
