import { useState, useEffect, useRef } from "react";

const CLIENT_ID =
  "596303005045-acge4de1vqqngtpi417i5cut1qeha5jh.apps.googleusercontent.com";

export function useAuthentication() {
  const [idToken, setIdToken] = useState(null);
  const buttonRef = useRef(null);
  const [forceRenderState, forceRender] = useState(false);

  useEffect(() => {
    if (idToken !== null) {
      return;
    }

    if (window.google === undefined) {
      const timeoutId = setTimeout(() => {
        forceRender(() => !forceRenderState);
      }, 500);
      return () => clearTimeout(timeoutId);
    }

    window.google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: (response) => {
        setIdToken(response.credential);
        buttonRef.current?.remove();
      },
      context: "signin",
      ux_mode: "popup",
      auto_select: "true",
      itp_support: "true",
    });

    if (buttonRef.current !== null) {
      window.google.accounts.id.renderButton(buttonRef.current, {
        theme: "outline",
        size: "large",
        type: "standard",
        text: "signin_with",
        logo_alignment: "left",
        shape: "rectangular",
      });
    }
    window.google.accounts.id.prompt();
  }, [buttonRef, idToken, forceRenderState]);

  return [idToken, setIdToken, buttonRef];
}
