import { io } from "socket.io-client";
import { useEffect, useState, useCallback, useContext } from "react";
import { CredentialContext } from "./utils";
import _ from "lodash";

export function useSocketIo(initalState) {
  const { idToken, setIdToken } = useContext(CredentialContext);
  const [socket, setSocket] = useState(null);
  const [state, internalSetState] = useState(initalState);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (socket !== null) {
      return;
    }

    const newSocket = io(
      window.location.protocol + "//" + window.location.host,
      {
        auth: (cb) => cb({ id_token: idToken }),
      }
    );
    setSocket(newSocket);
  }, [socket, idToken]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledEmit = useCallback(
    _.throttle((newState) => {
      socket.emit("update", newState);
    }, 250),
    [socket]
  ); // Throttle time of 5 seconds

  const setState = useCallback(
    (newState) => {
      if (isConnected === false) return;
      if (_.isEqual(newState, state)) return;

      throttledEmit(newState);
      internalSetState(newState);
    },
    [isConnected, state, throttledEmit]
  );

  useEffect(() => {
    if (socket === null) return;
    // function onConnect(socket) {}

    function onConnectionError(error) {
      if (error.message === "unauthorized") {
        console.log("Unauthorized");
        setIdToken(null);
      }
    }

    function onUpdate(message) {
      internalSetState(message);

      if (isConnected === false) {
        console.log("Connected");
        setIsConnected(true);
      }
      console.log("Received Update");
      if (message === state) return;
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    // socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onConnectionError);
    socket.on("update", onUpdate);

    return () => {
      // socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onConnectionError);
      socket.off("update", onUpdate);
    };
  }, [state, setState, isConnected, socket, setIdToken]);

  return [isConnected, state, setState];
}
