import React, { useRef } from "react";
import Row from "./Row";

export default function DrawingPanel(props) {
  const { selectedColor, setColors, pixels } = props;
  const width = pixels.length;
  const panelRef = useRef();

  const setPixelColor = (element) => {
    const panel = panelRef.current.getBoundingClientRect();

    const relativeX = element.clientX - panel.left;
    const relativeY = element.clientY - panel.top;
    const row = Math.floor((relativeY / panel.height) * width);
    const col = Math.floor((relativeX / panel.width) * width);

    if (row < 0 || row >= width || col < 0 || col >= width) {
      return;
    }
    if (pixels[row][col] === selectedColor) return;

    const newColors = pixels.map((rowColors, rowIndex) => {
      if (rowIndex !== row) return rowColors;
      return rowColors.map((color, colIndex) =>
        colIndex === col ? selectedColor : color
      );
    });

    setColors(newColors);
  };

  return (
    <div
      id="drawingPanel"
      onTouchMove={(event) => {
        setPixelColor(event.touches[0]);
      }}
      onClick={setPixelColor}
      onMouseMove={(event) => event.buttons & 1 && setPixelColor(event)}
    >
      <div id="pixels" ref={panelRef}>
        {pixels.map((pixelRow, i) => (
          <Row key={i} pixelRow={pixelRow} />
        ))}
      </div>
    </div>
  );
}
